import React, { useState, useEffect } from 'react';
import './FriendPage.css';
import { useNavigate } from 'react-router-dom';
import logo from './logo.png';
import { motion } from 'framer-motion';

const FriendPage = () => {
  const navigate = useNavigate(); 
  const [activeText, setActiveText] = useState(''); 
  const [displayedText, setDisplayedText] = useState(''); 
  const [typingIndex, setTypingIndex] = useState(0); 
  const [isQuestionSelected, setIsQuestionSelected] = useState(false);

  // Predefined text for each button
  const responses = {
    first: "I wanted to set up a page that will allow people to get to know me more, either from a professional or personal standpoint.\n\nNot to mention, it's a fun project to set up a site all about me!",
    second: "... ERROR NO VALUE FOUND\n\n;)",
    third: `If you know me, you know one thing - I live a boring life.\n\nMy ideal day surrounds 8 hours of sleep, 1 hour in the gym, and at least 4 hours of eating.\n\nBut a bit more seriously, I do enjoy those things as well as catching up on new technology, either software or hardware, doing small side projects, watching my Memphis Grizzlies, and most importantly, sleep.`
  };

  const handleLogoClick = () => {
    navigate('/'); 
  };

  const handleClick = (key) => {
    setActiveText(responses[key]); 
    setDisplayedText(''); 
    setTypingIndex(0); 
    setIsQuestionSelected(true); 
  };

  // Typewriter effect logic for both predefined and custom responses
  useEffect(() => {
    if ((activeText) && typingIndex < (activeText.length)) {
      const timeoutId = setTimeout(() => {
        const textToType = activeText;
        setDisplayedText(prev => prev + textToType[typingIndex]);
        setTypingIndex(typingIndex + 1);
      }, 25); // Quick typing effect

      return () => clearTimeout(timeoutId); 
    }
  }, [activeText, typingIndex]);

  return (
    <div className="friend-page">
      {/* Logo and Name Section */}
        <div className="header" onClick={handleLogoClick}>
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="logo-text">meet Amaj</h1>
      </div>

      {!isQuestionSelected && (
        <motion.div
          className="main-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
        <p className="subheading">what would you like to know?</p>
      </motion.div>
      )}

      {/* Buttons fixed at the top */}
      <motion.div
      className="fixed-buttons"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 0.5 }}
      >
        <button className="question-button" onClick={() => handleClick('first')}>what is this page?</button>
        <button className="question-button" onClick={() => handleClick('second')}>how much do you make?</button>
        <button className="question-button" onClick={() => handleClick('third')}>what do you like doing?</button>
      
      </motion.div>

      {/* Fade-in for typewriter response */}
      <motion.div
        className="center-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <p className="typewriter-text">{displayedText}</p>
      </motion.div>
    </div>
  );
};

export default FriendPage;